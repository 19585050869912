import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import pembayaran from '../../../assets/img/akademi/tagihan-siswa/pembayaran.png';
import riwayat_pembayaran from '../../../assets/img/akademi/tagihan-siswa/riwayat_pembayaran.png';
import rincian_pembayaran from '../../../assets/img/akademi/tagihan-siswa/rincian_pembayaran.png';

export default class Spp extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
    this.onClickProsesPembayaran = this.onClickProsesPembayaran.bind(this);
    this.onClickRincianPembayaran = this.onClickRincianPembayaran.bind(this);
    this.onClickRiwayat = this.onClickRiwayat.bind(this);
  }
  

  onClick(link) {
    const { history } = this.props;
    history.push(`/dashboard/tagihan-siswa//${link}`);
  }

  getClickHandler(link) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, link)) {
      this.clickHandlers[link] = () => this.onClick(link);
    }
    return this.clickHandlers[link];
  }

  onClickProsesPembayaran() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/pembayaran`);
  }

  onClickRincianPembayaran() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/rincian-pembayaran`);
  }

  onClickRiwayat() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/riwayat`);
  }

  render() {
    
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickProsesPembayaran}>
            <img src={pembayaran} alt="pembayaran" />
            <h3>Pembayaran</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickRiwayat}>
            <img src={riwayat_pembayaran} alt="riwayat_pembayaran" />
            <h3>Riwayat Pembayaran</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickRincianPembayaran}>
            <img src={rincian_pembayaran} alt="rincian_pembayaran" />
            <h3>Rincian Pembayaran</h3>
          </button>
        </div> */}
      </div>
    );
  }
}
Spp.propTypes = {
  history: PropTypes.object.isRequired,
};