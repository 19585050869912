import { connect } from 'react-redux';
import Riwayat from '../../../../views/Finance/Spp/Riwayat/Riwayat.view';
import { getParamOptionsAcademics, getPaymentsTypeOptions } from '../../../../states/thunks/options.thunk';
import { historyDetailPaymentsRutin, historyDetailPaymentsOffline, 
  historyDetailPaymentsH2H, historyDetailPaymentsGeneralRutinVA, historyDetailPaymentsAll } from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleHistoryDetailPayments: (payload, goback) => dispatch(historyDetailPaymentsRutin(payload, goback)),
    handleHistoryDetailPaymentsOffline: (payload, goback) => dispatch(historyDetailPaymentsOffline(payload, goback)),
    handleHistoryDetailPaymentsH2H: (payload, goback) => dispatch(historyDetailPaymentsH2H(payload, goback)),
    handleHistoryPaymentsGeneralRutinVA: (payload, goback) => dispatch(historyDetailPaymentsGeneralRutinVA(payload, goback)),
    getPaymentsTypeOptions: (payload, goback) => dispatch(getPaymentsTypeOptions(payload, goback)),
    handleHistoryDetailPaymentsAll: (payload, goback) => dispatch(historyDetailPaymentsAll(payload, goback)),
  
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Riwayat);



