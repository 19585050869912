import axios from 'axios';
import { map } from 'lodash';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';

const { CancelToken } = axios;
let source;


export function getUnitOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/unit');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'unit',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getParamOptionsAcademics(payload = {}, type = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      // if(type === 'classes'){
      //   payload.filters = {
      //     organizations_id: 3
      //   };
      // }
      let defaultValue = {
        id: '0',
        title: 'SEMUA',
        value: '0',
        label: 'SEMUA'
      };
      const res = await api.ACADEMICS_PARAM({ filters: {
         ...payload,
        }}, `/param/${type}`);
      const data = map(res, item => ({
        label: item.name,
        value: item.id || item.name,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: type,
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getYearsOfEducationsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload, organizations_id}, '/3/years_of_education')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPaymentsTypeOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload, organizations_id}, '/prm_payments_type')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}