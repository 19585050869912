import { connect } from 'react-redux';
import Login from '../views/Login/Login.view';
import { handleLogin, getSubdomain } from '../states/thunks/auth.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleLogin: (payload, push) => dispatch(handleLogin(payload, push)),
    getSubdomain: (payload, push) => dispatch(getSubdomain(payload, push)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
