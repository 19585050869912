import { connect } from 'react-redux';
import Pembayaran from '../../../../views/Finance/Spp/Pembayaran/Pembayaran.view';
import { getParamOptionsAcademics, getYearsOfEducationsOptions } from '../../../../states/thunks/options.thunk';
import { listInvoiceRutin, listInvoiceNonRutin, listPaymentGateway, saveTemporary, detailPayments, 
  cancelPayments, listInvoiceRutinGeneral, listInvoiceNonRutinGeneral, listPaymentGatewayGeneral, 
  detailPaymentsGeneral, cancelPaymentsGeneral, detailPaymentsVAGeneral, cancelPaymentsVAGeneral } from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    handleListInvoiceRutin: (payload, goback) => dispatch(listInvoiceRutin(payload, goback)),
    handleListInvoiceNonRutin: (payload, goback) => dispatch(listInvoiceNonRutin(payload, goback)),
    handleListInvoiceRutinGeneral: (payload, goback) => dispatch(listInvoiceRutinGeneral(payload, goback)),
    handleListInvoiceNonRutinGeneral: (payload, goback) => dispatch(listInvoiceNonRutinGeneral(payload, goback)),
    handleListPaymentGateway: (payload, goback) => dispatch(listPaymentGateway(payload, goback)),
    handleListPaymentGatewayGeneral: (payload, goback) => dispatch(listPaymentGatewayGeneral(payload, goback)),
    // handleSaveTemporary: (payload, goback) => dispatch(saveTemporary(payload, goback)),
    handleDetailPayments: (payload, goback) => dispatch(detailPayments(payload, goback)),
    handleDetailPaymentsVAGeneral: (payload, goback) => dispatch(detailPaymentsVAGeneral(payload, goback)),
    handleDetailPaymentsGeneral: (payload, goback) => dispatch(detailPaymentsGeneral(payload, goback)),
    handleCancelPayments: (payload, goback) => dispatch(cancelPayments(payload, goback)),
    handleCancelPaymentsVAGeneral: (payload, goback) => dispatch(cancelPaymentsVAGeneral(payload, goback)),
    handleCancelPaymentsGeneral: (payload, goback) => dispatch(cancelPaymentsGeneral(payload, goback)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Pembayaran);



